<template>
  <base-card v-bind="$attrs" v-on="$listeners">
    <slot slot="headers" name="headers"></slot>
    <slot slot="body" name="body" />
    <slot />
    <apexchart
      :height="height"
      :options="chartOptions"
      :series="this.params.series"
    ></apexchart>
  </base-card>
</template>

<script>
import BaseCard from "./BaseCard";

export default {
  name: "CountCard",
  components: {
    BaseCard,
  },
  created() {},
  destroyed() {},
  inheritAttrs: false,
  props: {
    params: {
      type: Object,
      default: () => ({ labels: [], series: {} }),
    },
    title: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "100",
    },
    legend: {
      type: Boolean,
      default: false,
    },
    eventHandlers: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    ratio: {
      type: String,
      default: undefined,
    },
    responsiveOptions: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      required: true,
      validator: (v) => ["donut", "bar", "line", "pie", "area"].includes(v),
    },
  },
  data: () => ({
    loading: true,
  }),
  computed: {
    chartOptions() {
      return {
        chart: {
          type: this.type,
          // toolbar: {
          //   show: false,
          // },
          // zoom: {
          //   enabled: false,
          // },
          sparkline: {
            enabled: true,
          },
        },
        labels: this.params.labels,
        dataLabels: {
          enabled: this.legend,
        },
        legend: {
          show: this.legend,
          labels: {
            colors: undefined,
            useSeriesColors: true,
          },
        },
        tooltip: {
          followCursor: true,
          theme: "dark",
        },
      };
    },
  },
  methods: {
    resizeChart() {
      // let width = this.$refs.chartRef
    },
  },
};
</script>

<style lang="sass">
.v-card--material-chart
  p
    color: #999

  .v-card--material__heading
    max-height: 185px

    .ct-label
      color: inherit
      opacity: .7
      font-size: 0.975rem
      font-weight: 100

    .ct-grid
      stroke: rgba(255, 255, 255, 0.2)

    .ct-series-a .ct-point,
    .ct-series-a .ct-line,
    .ct-series-a .ct-bar,
    .ct-series-a .ct-slice-donut
      stroke: rgba(255,255,255,.8)

    .ct-series-a .ct-slice-pie,
    .ct-series-a .ct-area
      fill: rgba(255,255,255,.4)
</style>
