<template>
  <v-card dark class="d-flex flex-grow-1 primary darken-4" min-height="380">
    <div class="d-flex flex-column flex-grow-1">
      <v-card-title v-if="$slots.headers">
        <slot name="headers" />
      </v-card-title>

      <div class="d-flex flex-column flex-grow-1" v-if="$slots.body">
        <slot name="body"></slot>
      </div>
      <slot />
    </div>
  </v-card>
</template>

<script>
export default {
  name: "BaseCard",
  props: {
    avatar: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "success",
    },
    icon: {
      type: String,
      default: undefined,
    },
    image: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "",
    },
  },
  computed: {
    classes() {
      return {
        "v-card--material--has-heading": this.hasHeading,
      };
    },
    hasHeading() {
      return Boolean(this.$slots.heading || this.title || this.icon);
    },
    hasAltHeading() {
      return Boolean(this.$slots.heading || (this.title && this.icon));
    },
  },
};
</script>

<style lang="sass">
.v-card--material
  &__avatar
    //position: relative
    //top: -64px
    //margin-bottom: -32px

  &__heading
    //position: relative
    //top: -10px
    //transition: .3s ease
    //z-index: 1
</style>
