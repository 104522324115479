<template>
  <v-card>
    <v-snackbar v-model="snackbar" :timeout="5000">
      {{ snackbar_text }}
    </v-snackbar>
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="params"
      no-data-text="Зарядные сессии не найдены"
    >
      <template v-slot:item.id="{ item }"> # {{ item.id }} </template>
      <template v-slot:item.point_number="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <router-link
              v-bind="attrs"
              v-on="on"
              class="link_label"
              :to="{
                name: 'point_info',
                params: { point_id: item.point_number },
              }"
              >{{ item.point_number }}
            </router-link>
          </template>
          <span>Копировать</span>
        </v-tooltip>
      </template>
      <template v-slot:item.amount="{ item }"> {{ item.amount }} kWt </template>
      <template v-slot:item.duration="{ item }">
        {{ duration(item.created_at, item.updated_at) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <router-link
              v-bind="attrs"
              v-on="on"
              class="text-decoration-none"
              :to="{
                name: 'charge_info',
                params: { charge_id: item.id },
              }"
              ><v-icon>mdi-open-in-new</v-icon></router-link
            >
          </template>
          <span>Открыть в новом окне</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: "TableCard",
  props: {
    title: {
      type: String,
      required: true,
    },
    params: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      snackbar: false,
      snackbar_text: "Текст скопирован в буфер",
      search: "",
      headers: [
        {
          text: "ID зарядки",
          align: "start",
          value: "id",
        },
        {
          text: "Клиент",
          align: "start",
          sortable: false,
          value: "client.phone",
        },
        { text: "Станция", value: "charging_point_number" },
        { text: "Разьем", value: "reserved_connector" },
        { text: "Заряд", value: "power_amount" },
        {
          text: "Продолжительность зарядки",
          value: "duration",
          align: "center",
        },
        { text: "Окончание зарядки", value: "updated_at" },
        { text: "", value: "actions", sortable: false },
      ],
      desserts: [
        {
          charge_id: 10,
          name: "Тест Тестов",
          charge_point: 9971103,
          connector: "type2",
          charge: 24,
          charge_time: 49,
          end_time: "12:22 01/10/2021",
        },
        {
          charge_id: 9,
          name: "Пал Палыч",
          charge_point: 31519099,
          connector: "chademo",
          charge: 37,
          charge_time: 134,
          end_time: "12:12 01/10/2021",
        },
        {
          charge_id: 8,
          name: "Тестыч Тестов",
          charge_point: 41516023,
          connector: "type2",
          charge: 23,
          charge_time: 83,
          end_time: "11:52 01/10/2021",
        },
        {
          charge_id: 7,
          name: "Тест Тестов",
          charge_point: 19402061,
          connector: "type2",
          charge: 67,
          charge_time: 32,
          end_time: "11:49 01/10/2021",
        },
        {
          charge_id: 6,
          name: "Тест Тестов",
          charge_point: 71140650,
          connector: "chademo",
          charge: 49,
          charge_time: 80,
          end_time: "11:22 01/10/2021",
        },
        {
          charge_id: 5,
          name: "Тест Тестов",
          charge_point: 51583647,
          connector: "type2",
          charge: 94,
          charge_time: 18,
          end_time: "11:09 01/10/2021",
        },
        {
          charge_id: 4,
          name: "Тест Тестов",
          charge_point: 7230511,
          connector: "type2",
          charge: 98,
          charge_time: 4,
          end_time: "11:01 01/10/2021",
        },
        {
          charge_id: 3,
          name: "Тест Тестов",
          charge_point: 64740964,
          connector: "type2",
          charge: 87,
          charge_time: 6.5,
          end_time: "10:43 01/10/2021",
        },
        {
          charge_id: 2,
          name: "Тест Тестов",
          charge_point: 7230511,
          connector: "type2",
          charge: 51,
          charge_time: 4.9,
          end_time: "10:22 01/10/2021",
        },
        {
          charge_id: 1,
          name: "Тест Тестов",
          charge_point: 41516023,
          connector: "chademo",
          charge: 65,
          charge_time: 7,
          end_time: "12:22 01/10/2021",
        },
      ],
    };
  },
  methods: {
    copy_text(text) {
      navigator.clipboard.writeText(text);
      this.snackbar = true;
    },
    duration(start, end) {
      // let end = time.split(".");
      // if (end.length > 0) return end[0];
      console.log(start);
      console.log(end);
      return "00:00:00";
    },
  },
};
</script>

<style lang="scss">
.link_label {
  color: black !important;
  text-decoration: none;
  cursor: pointer;
  border-bottom: 1px black dashed;
}
</style>
