<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" lg="4">
        <count-card
          :params="charging.params"
          title="Заправки"
          color="#00355e"
          type="area"
        >
          <template v-slot:headers>
            <div class="">Зарядные сессии</div>
            <v-spacer></v-spacer>
            <v-btn text dark color="primary">Отчет</v-btn>
          </template>
          <template v-slot:body>
            <div class="pa-2">
              <div class="text-h4">{{ charging.total }} шт</div>
              <div class="primary--text text--lighten-1 mt-1">
                {{ charging.last_week }} шт по сравнению с прошлой неделей
              </div>
            </div>
            <div class="spacer"></div>
            <div class="px-2 pb-2">
              <div class="title mb-1">Сегодняшние зарядки</div>
              <div class="d-flex text-center">
                <div class="text-h4">{{ charging.today }} шт</div>
                <v-spacer></v-spacer>
                <div class="d-flex flex-column text-right">
                  <div class="font-weight-bold">
                    <span>
                      <span class="success--text">
                        <v-icon color="success" size="16"
                          >mdi-arrow-top-right</v-icon
                        >
                        {{ charging.last_week * (charging.today / 100) }}%
                      </span>
                    </span>
                    <div class="caption">по сравнению с прошлой неделей</div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </count-card>
      </v-col>
      <v-col cols="12" lg="4">
        <count-card
          :params="status"
          title="Клиенты"
          color="#00355e"
          type="area"
        >
          <template v-slot:headers>
            <div class="">Зарядные станции</div>
            <v-spacer></v-spacer>
            <v-btn text dark color="primary">Отчет</v-btn>
          </template>
          <template v-slot:body>
              <div class="pa-4">
                <div class="text-h2">{{ status.online }} / {{ status.offline }}</div>
                <div class="primary--text text--lighten-1 mt-1">
                  онлайн / оффлайн
                </div>
              </div>

          </template>
        </count-card>
      </v-col>
      <v-col cols="12" lg="4">
        <count-card
          :params="status"
          color="#00355e"
          type="area"
          legend
        >
          <template v-slot:headers>
            <div class="">Энергия</div>
            <v-spacer></v-spacer>
            <v-btn text dark color="primary">Отчет</v-btn>
          </template>
          <template v-slot:body>
            <div class="text-center">
              <div class="text-h2">{{ status.total_power/1000 }}</div>
              <div class="primary--text text--lighten-1 mt-1">
                кВт
              </div>
            </div>
          </template>
        </count-card>
      </v-col>
      <v-col>
        <table-card title="Недавние заправки" :params="latest" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CountCard from "../components/card/CountCard";
import TableCard from "../components/card/TableCard";

export default {
  name: "Dashboard",
  components: {
    CountCard,
    TableCard,
  },
  created() {
    this.$store.dispatch("dashboard");
    this.$store.dispatch("latest_charges");
  },
  data() {
    return {

    };
  },
  computed: {
    charging() {
      return this.$store.getters.DASHBOARD.charges;
    },
    status() {
      return this.$store.getters.DASHBOARD.status;
    },
    points() {
      return this.$store.getters.DASHBOARD.points;
    },
    latest() {
      return this.$store.getters.LATEST_CHARGES;
    },
  },
};
</script>

<style scoped></style>
